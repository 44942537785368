main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}
